import { useEvent } from 'react-use';
import './Search.scss';

import { memo, useRef, useState } from 'react';

export default memo(function Search({ onChange, ...props }) {
    const [value, setValue] = useState('');
    const inputRef = useRef();

    // on CTRL + F focus on search input
    useEvent('keydown', e => {
        if (e.ctrlKey && e.key === 'f') {
            e.preventDefault();
            inputRef.current.focus();
        }
    });

    return (
        <div className="input-search-wrapper">
            <input
                ref={inputRef}
                type="text"
                className="input-search"
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e);
                }}
                {...props}
            />

            {value && (
                <span
                    className="input-clear"
                    onClick={() => {
                        setValue('');
                        onChange({ target: { value: '' } });

                        inputRef.current.focus();
                    }}>
                    <Icon name="mdi:close" />
                </span>
            )}
        </div>
    );
});
