import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from '@reducers/projects';
import configsReducer from '@reducers/configs';

export const store = configureStore({
    reducer: {
        configs: configsReducer,
        projects: projectsReducer,
    },
    devTools: true,
});
