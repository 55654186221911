import './InputCheckbox.scss';

import { memo } from 'react';

import classNames from 'classnames';

export default memo(function InputCheckbox({ label, handler, isActive, type = 'default', icon }) {
    return (
        <p
            className={classNames('checkbox-option', isActive && 'active')}
            data-checkbox-type={type}
            onClick={handler}>
            {icon && <Icon name={icon} />}
            {label}
        </p>
    );
});
