import './Project.scss';

import Content from './Content';
import Placeholder from './Placeholder';
import ContextMenu from '@components/ContextMenu/ContextMenu';
import { selectIsCurrentProject, updateNavigationIndex, updateProjectInViewport } from '@reducers/projects';

import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { useEvent } from 'react-use';
import { isEqual } from 'lodash';

const Project = forwardRef(function Project({ id }, ref) {
    const [inViewRef, inView] = useInView();
    const [placeholderHeight, setPlaceholderHeight] = useState();
    const [projectHeight, setProjectHeight] = useState();

    const projectData = useSelector(state => state.projects.projects.find(project => project._id === id), isEqual);
    const current = useSelector(state => selectIsCurrentProject(state, id));

    const isFocused = useSelector(state => state.projects.projects.find(project => project._id === id)?.focused, isEqual);

    const projectRef = useRef();

    useEffect(() => {
        if (!projectRef.current) return;
        const resizeObserver = new ResizeObserver(entries => {
            const newHeight = entries[0].contentRect.height;

            if (newHeight > 0 && newHeight !== projectHeight) setProjectHeight(newHeight);
        });
        resizeObserver.observe(projectRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (inView && projectHeight > 0) setPlaceholderHeight(projectHeight);
    }, [inView, projectHeight]);

    const dispatch = useDispatch();

    const [contextMenuEvent, setContextMenuEvent] = useState();
    const placeholderStyle = useMemo(() => ({ minHeight: placeholderHeight }), [placeholderHeight]);

    useEffect(() => {
        // send the id and the inView status to the store
        dispatch(updateProjectInViewport({id, inViewport: inView }));
    }, [id, inView]);

    // when the focused project changes, scroll to it
    useEffect(() => {
        if (!isFocused) return;

        const focusedProject = projectRef.current;

        // get distance from the top of the page to measure how much to scroll
        const distanceFromTop = focusedProject.getBoundingClientRect().top;

        // calculate the offset to the project be in the middle of the screen
        const offset = window.innerHeight / 2 - focusedProject.clientHeight / 2;

        // scroll to the project
        window.scrollTo({
            top: window.scrollY + distanceFromTop - offset,
            behavior: 'smooth',
        });
    }, [isFocused]);

    
    // when press enter and has a focused project, open it in a new tab unfocused
    useEvent('keydown', e => {
        if (e.keyCode !== 13 || !isFocused) return;

        e.preventDefault();

        const focusedProjectUrl = projectData?.url;
        if (!focusedProjectUrl) return;

        window.open(focusedProjectUrl, '_blank');
    });

    return (
        <>
            <div
                ref={el => {
                    inViewRef(el);
                    projectRef.current = el;
                    if (ref) ref(el, id);
                }}
                key={id}
                className={classNames('project-item', {
                    'context-menu-open': contextMenuEvent,
                    'last-project': current,
                    'out-of-view': !inView,
                    'is-focused': isFocused,
                })}
                onDoubleClick={() => dispatch(updateNavigationIndex(id))}
                onContextMenu={setContextMenuEvent}
                onMouseLeave={() => setContextMenuEvent(undefined)}>
                {current && <span className="last-project">último projeto</span>}

                {inView ? <Content id={id} /> : <Placeholder style={placeholderStyle} />}

                {contextMenuEvent && (
                    <ContextMenu
                        projectRef={projectRef}
                        event={contextMenuEvent}
                        onClose={() => setContextMenuEvent(undefined)}
                        id={id}
                    />
                )}
            </div>
        </>
    );
});

export default memo(Project);