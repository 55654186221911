import Loader from '@components/Loader/Loader';
import Projects from '@components/Projects/Projects';
import Sidebar from '@components/Sidebar/Sidebar';
import { fetchProjects, getClassifications } from '@reducers/projects';

import { useCallback } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useEffectOnce, useEvent } from 'react-use';

export default function App() {
    const dispatch = useDispatch();

    useEffectOnce(() => {
        dispatch(fetchProjects());
        dispatch(getClassifications());
    });

    useEvent('contextmenu', e => e.preventDefault());

    const containerStyle = useCallback(
        () => ({
            top: 60,
        }),
        []
    );

    return (
        <div className="app-container">
            <Sidebar />
            <Projects />

            <Loader />
            <Toaster containerStyle={containerStyle} />
        </div>
    );
}
