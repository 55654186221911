import './Text.scss';

import { memo } from 'react';

export default memo(function Text(props) {
    return (
        <div className="input-wrapper">
            <input type="text" className="input-text" {...props} />
        </div>
    );
});
