import { selectProject } from '@reducers/projects';

import { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

export default memo(function Title({ id }) {
    const { title, url } = useSelector(
        state => selectProject(state, id, ['title', 'url']),
        shallowEqual
    );

    return (
        <h2 className="project-title">
            <a href={url}>{title}</a>
        </h2>
    );
});
