import dayjs from 'dayjs';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';

export default memo(function LastUpdateTime() {
    const lastUpdate = useSelector(state => state.projects.lastUpdate);

    const [lastUpdateDate, setLastUpdateDate] = useState(null);

    useInterval(() => {
        if (!lastUpdate) return;
        setLastUpdateDate(dayjs(lastUpdate).fromNow());
    }, !lastUpdateDate ? 0 : 1000);

    return lastUpdateDate && <p className="last-update">Atualizado {lastUpdateDate}</p>;
});
