import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
    projects: [],
    categorizedProjects: {
        interest: [],
        noInterest: [],
        maybe: [],
    },
    projectsCount: null,
    storedProjects: [],
    isLoaded: false,
    isLoading: false,
    navigationIndex: 0,
    lastUpdate: null,
    classifications: {
        0: 0,
        1: 0,
    },
};

export const getClassifications = createAsyncThunk(
    'projects/getClassifications',
    async (_, { rejectWithValue, getState }) => {
        const state = getState()?.projects;

        try {
            const response = await fetch(import.meta.env.VITE_API_URL + 'classifications');
            const data = await response?.json();
            const fetchedClassifications = data?.classifications;

            return fetchedClassifications || { 0: 0, 1: 0 };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchProjects = createAsyncThunk(
    'projects/fetchProjects',
    async (_, { rejectWithValue, getState }) => {
        const state = getState()?.projects;
        const { stored } = state;

        try {
            const response = await fetch(import.meta.env.VITE_API_URL);
            const data = await response.json();
            const fetchedProjects = data.projects;

            const newProjects = fetchedProjects?.map(proj => {
                const storedIndex = stored?.findIndex(p => p._id === proj._id);
                const newProject = storedIndex >= 0 ? stored[storedIndex] : proj;
                return {
                    ...newProject,
                    descriptionCollapsed: false,
                    classification: proj.classification !== undefined ? proj.classification : null,
                    noInterest: proj.predict?.[0],
                    interest: proj.predict?.[1],
                    classificationPredict: proj.predict.indexOf(Math.max(...proj.predict)),
                };
            });
            return {
                projects: newProjects,
                lastUpdate: data.lastUpdate,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const getCategory = project => {
    const predicted = project?.predict?.indexOf(Math.max(...project?.predict));
    const predictAccuracy = project.predict[predicted] * 100;
    const accuracyThreshold = 70;

    if (predictAccuracy <= accuracyThreshold) return 'maybe';
    else return predicted == 1 ? 'interest' : 'noInterest';
};

const categorize = projects => {
    return projects.reduce(
        (acc, curr) => {
            const category = getCategory(curr);
            acc[category].push(curr);

            return acc;
        },
        { interest: [], noInterest: [], maybe: [] }
    );
};

// const selectProjects = state => state.projects.projects;

const selectAllProjects = state => state.projects.projects;
// const selectFilteredProjects = state => state.projects.projects;
// const selectCategorizedProjects = state => state.projects.projects;

export const selectSortedProjects = createSelector(
    [selectAllProjects, state => state.configs.sorting],
    (projects, sorting) => {
        if (!sorting) return projects;

        const sortedProjects = _.orderBy(projects, sorting.key, sorting.order);
        return sortedProjects;
    }
);

const treatTextToSearch = text => {
    let treatedText = text;

    // Remove accents
    treatedText = treatedText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Remove special characters
    treatedText = treatedText.replace(/[^a-zA-Z0-9 ]/g, '');

    // Remove multiple spaces
    treatedText = treatedText.replace(/\s\s+/g, ' ');

    // Lowercase
    treatedText = treatedText.toLowerCase();

    return treatedText;
};

const selectSearchedProjects = createSelector(
    [selectSortedProjects, state => state.configs.search],
    (projects, search) => {
        if (!search) return projects;

        const searchResult = projects.filter(project => {
            const author = treatTextToSearch(project.author.name);
            const title = treatTextToSearch(project.title);
            const description = treatTextToSearch(project.description);
            const searchLower = treatTextToSearch(search);

            return (
                title.includes(searchLower) ||
                description.includes(searchLower) ||
                author.includes(searchLower)
            );
        });

        return searchResult;
    }
);

export const selectFilteredProjects = createSelector(
    [
        selectSearchedProjects,
        state => state.configs.activeCategories,
        state => state.configs.isAllCategoriesActive,
    ],
    (projects, activeCategories, isAllCategoriesActive) => {
        if (isAllCategoriesActive) return projects;

        const activeProjects = projects.filter(project => {
            const category = getCategory(project);
            return activeCategories.includes(category);
        });

        return activeProjects;
    }
);

export const selectCategorizedProjects = createSelector([selectFilteredProjects], projects =>
    categorize(projects)
);

export const selectProjectsCount = createSelector([selectSearchedProjects], projects => {
    const categorizedProjects = categorize(projects);
    const categorizedProjectsCount = _.mapValues(categorizedProjects, value => value.length);
    categorizedProjectsCount.total = _.sum(Object.values(categorizedProjectsCount));

    return categorizedProjectsCount;
});

export const selectVisibleProjectsCount = createSelector([selectFilteredProjects], projects => {
    const visibleProjectsCount = projects.length;

    return visibleProjectsCount;
});

export const selectProjectIndexById = createSelector(
    [selectFilteredProjects, (_, id) => id],
    (projects, id) => {
        const projectIndex = _.findIndex(projects, { _id: id });
        return projectIndex;
    }
);

export const selectIsCurrentProject = createSelector(
    [selectFilteredProjects, (_, id) => id],
    (projects, id) => {
        const projectIndex = _.findIndex(projects, { _id: id });
        const isCurrentProject = projects[projectIndex]?.current;

        return isCurrentProject;
    }
);

export const selectPreviousProjectsIds = createSelector(
    [selectFilteredProjects, (_, id) => id],
    (projects, id) => {
        const projectIndex = _.findIndex(projects, { _id: id });
        const previousProjects = projects.slice(0, projectIndex + 1);
        const previousProjectsIds = previousProjects.map(project => project._id);

        return previousProjects;
    }
);

export const selectProject = createSelector(
    [selectAllProjects, (_, id, properties) => ({ id, properties })],
    (projects, { id, properties }) => {
        const project = projects?.find(p => p._id === id);

        if (!properties) return project;

        const projectProperties = _.pick(project, properties);
        return projectProperties;
    }
);

export const selectProjectTitle = createSelector([selectProject], project => project?.title);
export const selectProjectDescription = createSelector(
    [selectProject],
    project => project?.description
);
export const selectProjectUrl = createSelector([selectProject], project => project?.url);
export const selectProjectDate = createSelector([selectProject], project => project?.date);
export const selectProjectSkills = createSelector([selectProject], project => project?.skills);
export const selectProjectAuthor = createSelector([selectProject], project => project?.author);

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        updateClassification(state, { payload }) {
            const { _id, classification } = payload;
            const projectIndex = _.findIndex(state.projects, { _id });
            const project = state.projects[projectIndex];
            if (project.classification === classification) project.classification = null;
            else project.classification = classification;
            state.categorizedProjects = categorize(state.projects);
        },
        updateNavigationIndex(state, { payload: _id }) {
            const projectIndex = _.findIndex(state.projects, { _id });
            if (state.navigationIndex === projectIndex) return;

            _.map(state.projects, key => (key.current = key._id === _id));

            state.navigationIndex = _.findIndex(state.projects, { _id });
            state.categorizedProjects = categorize(state.projects);
        },
        updateStoredProjects(state, { payload }) {
            state.storedProjects = payload;
        },
        closeAllDescriptions(state) {
            _.map(state.projects, key => (key.descriptionCollapsed = false));
            state.categorizedProjects = categorize(state.projects);
        },
        toggleDescription(state, { payload: _id }) {
            const projectIndex = _.findIndex(state.projects, { _id });
            const isDescriptionCollapsed = state.projects[projectIndex]?.descriptionCollapsed;
            state.projects[projectIndex].descriptionCollapsed = !isDescriptionCollapsed;
            state.categorizedProjects = categorize(state.projects);
        },
        closeDescription(state, { payload: _id }) {
            const projectIndex = _.findIndex(state.projects, { _id });
            state.projects[projectIndex].descriptionCollapsed = false;
            state.categorizedProjects = categorize(state.projects);
        },
        updateProjectInViewport(state, { payload: { id, inViewport } }) {
            const projectIndex = _.findIndex(state.projects, { _id: id });
            state.projects[projectIndex].inViewport = inViewport;

            if (!inViewport) {
                state.projects[projectIndex].focused = false;
            }
        },
        unfocusAllProjects(state) {
            _.map(state.projects, key => (key.focused = false));
        },
        navigateFocusedProject(state, { payload: { id } }) {
            _.map(state.projects, key => (key.focused = false));
            
            const index = state.projects.findIndex(project => project._id === id);
            state.projects[index].focused = true;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchProjects.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                const projectsList = action.payload.projects;
                const lastUpdate = action.payload.lastUpdate;

                state.projects = projectsList;
                state.projectsCount = projectsList?.length;
                state.categorizedProjects = categorize(projectsList);

                state.lastUpdate = lastUpdate;

                state.isLoaded = true;
                state.isLoading = false;
            })
            .addCase(fetchProjects.rejected, state => {
                state.isLoading = false;
            });

        builder.addCase(getClassifications.fulfilled, (state, action) => {
            const classifications = action.payload;
            state.classifications = classifications;
        });
    },
});

export const {
    updateStoredProjects,
    updateClassification,
    updateNavigationIndex,
    closeAllDescriptions,
    closeDescription,
    toggleDescription,
    updateProjectInViewport,
    unfocusAllProjects,
    navigateFocusedProject,
} = projectsSlice.actions;
export default projectsSlice.reducer;
