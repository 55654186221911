import './CheckboxList.scss';

import InputCheckbox from '@components/Input/InputCheckbox/InputCheckbox';

import { memo } from 'react';

import classNames from 'classnames';

export default memo(function CheckboxList({ options, title, type, children }) {
    return (
        <div className="checkbox-list">
            {title && <h3 className="checkbox-list__title">{title}</h3>}

            <div className={classNames('options-container', type && `type-${type}`)}>
                {options &&
                    options?.map(({ text, label, handler, active, icon }) => (
                        <InputCheckbox
                            icon={icon}
                            type={type}
                            key={label}
                            label={text}
                            handler={handler}
                            isActive={active}
                        />
                    ))}

                {children}
            </div>
        </div>
    );
});
