import { useSelector } from 'react-redux';

import { memo } from 'react';

export default memo(function ClassificationsCount() {
    const classifications = useSelector(state => state.projects.classifications);

    return (
        <div className="classifications-count">
            <h3>Classificações</h3>
            <ul>
                <li>
                    <Icon icon="mdi:heart-outline" />
                    <span>{classifications[0]}</span>
                </li>
                <li>
                    <Icon icon="mdi:heart" />
                    <span>{classifications[1]}</span>
                </li>
            </ul>
        </div>
    );
});
