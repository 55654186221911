import Classifications from './Classifications';
import Date from './Date';
import Description from './Description';
import Footer from './Footer';
import Title from './Title';

import { memo } from 'react';

export default memo(function Content({ id }) {
    return (
        <>
            <Classifications id={id} />
            <Date id={id} />
            <Title id={id} />
            <Description id={id} />
            <Footer id={id} />
        </>
    );
});
