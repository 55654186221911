import ProjectList from '@components/ProjectList/ProjectList';
import {
    navigateFocusedProject,
    selectFilteredProjects,
    unfocusAllProjects,
} from '@reducers/projects';
import { isEqual } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { useEvent } from 'react-use';

export default function Projects() {
    const isLoading = useSelector(state => state.projects.isLoading);
    const isLoaded = useSelector(state => state.projects.isLoaded);

    const dispatch = useDispatch();

    const projects = useSelector(state => selectFilteredProjects(state), isEqual);

    const focusedProject = useSelector(
        state => state.projects.projects.find(project => project.focused),
        isEqual
    );

    useEvent('keydown', e => {
        const allowedKeys = ['ArrowUp', 'ArrowDown'];
        if (!allowedKeys.includes(e.key)) return;

        e.preventDefault();

        const direction = e.key === 'ArrowUp' ? 'previous' : 'next';

        let idToFocus;

        if (focusedProject) {
            const focusedProjectIndex = projects.findIndex(
                project => project._id === focusedProject._id
            );
            const newFocusedProjectIndex =
                direction === 'next' ? focusedProjectIndex + 1 : focusedProjectIndex - 1;

            const indexToFocus = Math.min(Math.max(newFocusedProjectIndex, 0), projects.length - 1);
            idToFocus = projects[indexToFocus]._id;
        } else {
            // First in viewport
            idToFocus = projects.find(project => project.inViewport)._id;
        }

        dispatch(navigateFocusedProject({ id: idToFocus }));
    });

    // on any click, unfocus the project
    useEvent('click', () => {
        dispatch(unfocusAllProjects());
    });

    return <main>{!isLoading && isLoaded && <ProjectList />}</main>;
}
