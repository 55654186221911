import { getClassifications, selectProject, updateClassification } from '@reducers/projects';

import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious, useUpdateEffect } from 'react-use';

import classNames from 'classnames';
import { isEqual } from 'lodash';

export default memo(function Classifications({ id }) {
    const { classification, classificationPredict, predict, project_id } = useSelector(
        state =>
            selectProject(state, id, [
                'classification',
                'classificationPredict',
                'predict',
                'project_id',
            ]),
        isEqual
    );

    const dispatch = useDispatch();

    const handleClassification = async () => {
        const response = await fetch(`${import.meta.env.VITE_API_URL}project/${project_id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ classification }),
        });

        dispatch(getClassifications());

        return response;
    };

    const handleLike = useCallback(() => {
        dispatch(updateClassification({ _id: id, classification: 1 }));
    });
    const handleDislike = useCallback(() => {
        dispatch(updateClassification({ _id: id, classification: 0 }));
    });

    useUpdateEffect(() => {
        handleClassification();
    }, [classification]);

    const ClassificationIcons = ({ n, id }) => (
        <div className="classification-icons">
            <span
                className={classNames('classification-icon mark-interest', n == 1 && 'marked')}
                onClick={handleLike}>
                <Icon name="ant-design:like-filled" />
            </span>

            <span
                data-id={id}
                className={classNames('classification-icon mark-no-interest', n == 0 && 'marked')}
                onClick={handleDislike}>
                <Icon name="ant-design:dislike-filled" />
            </span>
        </div>
    );

    return (
        <>
            <div className="project-classifications">
                <span
                    className={classNames(
                        'classification-percentage',
                        classificationPredict === 1 ? 'interest' : 'no-interest'
                    )}>
                    {Number((predict?.[1] * 100).toFixed(2)).toLocaleString()}%
                </span>
            </div>

            {classification !== undefined && <ClassificationIcons n={classification} id={id} />}
        </>
    );
});
