import CheckboxList from '@components/CheckboxList/CheckboxList';
import { changeSortingType } from '@reducers/configs';

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function SortBy() {
    const sorting = useSelector(state => state.configs.sorting);
    const dispatch = useDispatch();

    const handleSortChange = useCallback((key, order) => {
        dispatch(changeSortingType({ key, order }));
    }, []);

    const items = [
        {
            icon: 'material-symbols:heart-check-rounded',
            text: 'Mais provável',
            label: 'mais-provavel',
            active: sorting.key === 'interest' && sorting.order === 'desc',
            handler: useCallback(() => handleSortChange('interest', 'desc'), []),
        },
        {
            icon: 'material-symbols:heart-minus',
            text: 'Menos provável',
            label: 'menos-provavel',
            active: sorting.key === 'interest' && sorting.order === 'asc',
            handler: useCallback(() => handleSortChange('interest', 'asc'), []),
        },
        {
            icon: 'mdi:sort-clock-descending',
            text: 'Mais recente',
            label: 'mais-recente',
            active: sorting.key === 'date' && sorting.order === 'desc',
            handler: useCallback(() => handleSortChange('date', 'desc'), []),
        },
        {
            icon: 'mdi:sort-clock-ascending',
            text: 'Mais antigo',
            label: 'mais-antigo',
            active: sorting.key === 'date' && sorting.order === 'asc',
            handler: useCallback(() => handleSortChange('date', 'asc'), []),
        },
    ];

    return <CheckboxList type="button" options={items} title="Ordem" onChange={console.log} />;
}
