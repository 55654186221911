import Input from '@components/Input/Input';
import { setSearch } from '@reducers/configs';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function Search() {
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(setSearch(searchValue));
    }, [searchValue]);

    const handleSearch = useCallback(e => {
        setSearchValue(e.target.value);
    }, []);

    return (
        <div className="search-container">
            <h3>Filtrar por palavras</h3>

            <Input.Search type="text" placeholder="Pesquisar" onChange={handleSearch} />
        </div>
    );
}
