// import './wdyr';

import '@scss/index.scss';

import App from './App';
import { store } from './store';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale(ptBR);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App />
    </Provider>
);
