import './ProjectList.scss';

import Project from '@components/Project/Project';
import { selectFilteredProjects, selectVisibleProjectsCount } from '@reducers/projects';

import { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { isEqual } from 'lodash';

export default memo(function ProjectList() {
    const detailed = useSelector(state => state.configs.detailed);

    const projectsIds = useSelector(
        state => selectFilteredProjects(state).map(project => project._id),
        isEqual
    );
    const visibleCount = useSelector(state => selectVisibleProjectsCount(state), isEqual);

    const projectRefs = useRef({});

    // store the project refs
    const addRefToProject = (el, projectId) => {
        if (el) projectRefs.current[projectId] = el;
    };

    return (
        <>
            {projectsIds?.length > 0 ? (
                <div className="projects-list">
                    {visibleCount === 0 && (
                        <h2 className="projects-count">Nenhum projeto encontrado.</h2>
                    )}

                    {visibleCount > 0 && (
                        <h2 className="projects-count">
                            Exibindo <b>{visibleCount}</b> projetos
                        </h2>
                    )}

                    <div className={classNames('projects-container', detailed && 'detailed')}>
                        {projectsIds?.map(id => (
                            <Project key={id} id={id} ref={addRefToProject} />
                        ))}
                    </div>
                </div>
            ) : (
                <h1 className="no-projects-found">Nenhum projeto encontrado.</h1>
            )}
        </>
    );
});
