import './ContextMenu.scss';

import { selectFilteredProjects, selectPreviousProjectsIds } from '@reducers/projects';

import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { findIndex, maxBy } from 'lodash';
import Icon from '@components/Icon/Icon';

export default function ContextMenu({ event, onClose, projectRef, id }) {
    const projectsIds = useSelector(state => selectFilteredProjects(state));
    const previousProjects = useSelector(state => selectPreviousProjectsIds(state, id));

    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const handleClearProjects = useCallback(() => {
        let projectsToUpdate = previousProjects.map(({ project_id, classification, _id }) => ({
            project_id,
            classification,
            current: _id === id,
        }));

        toast.promise(
            fetch(import.meta.env.VITE_API_URL, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projects: projectsToUpdate }),
            }),
            {
                loading: 'Limpando projetos...',
                success: 'Projetos limpos com sucesso!',
                error: 'Falha ao limpar os projetos.',
            }
        );
    }, [previousProjects]);

    const handleClearAllProjects = useCallback(() => {
        let projectsToUpdate = projectsIds.map(({ project_id, classification }) => ({
            project_id,
            classification,
            current: false,
        }));

        // find most recent project index using it's ISO datestring and set it as current
        const mostRecentProject = maxBy(projectsIds, ({ date }) => date);
        const mostRecentProjectIndex = findIndex(
            projectsIds,
            ({ date }) => date === mostRecentProject.date
        );

        projectsToUpdate[mostRecentProjectIndex].current = true;

        toast.promise(
            fetch(import.meta.env.VITE_API_URL, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projects: projectsToUpdate }),
            }),
            {
                loading: 'Limpando projetos...',
                success: 'Projetos limpos com sucesso!',
                error: 'Falha ao limpar os projetos.',
            }
        );
    }, []);

    useEffect(() => {
        if (!event) return;

        // prevent context menu from appearing when clicking on the context menu itself
        if (event.target.classList.contains('context-menu-container')) return;

        const element = projectRef.current;

        if (!element) return;

        const { pageX, pageY } = event;

        const x = pageX - element.offsetLeft;
        const y = pageY - element.offsetTop;

        setMenuPosition({ x, y });
    }, [event]);

    useEffect(() => {
        function handleClick() {
            setMenuPosition({ x: 0, y: 0 });

            onClose();
        }

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, []);

    const defaultOptions = [
        {
            text: 'Salvar projetos',
            icon: 'material-symbols:save-outline-rounded',
            handler: () => {},
        },
        {
            text: 'Remover projetos acima deste',
            icon: 'material-symbols:restore-from-trash-outline-rounded',
            handler: handleClearProjects,
        },
        {
            text: 'Remover todos os projetos',
            icon: 'material-symbols:delete-outline-rounded',
            handler: handleClearAllProjects,
        },
    ];

    return (
        <div
            className={classNames('context-menu-container', 'open')}
            style={{
                left: menuPosition.x,
                top: menuPosition.y,
            }}>
            {defaultOptions.map(({ text, icon, handler }) => (
                <button key={text} className="menu-item" onClick={handler}>
                    <Icon icon={icon} />
                    <span>{text}</span>
                </button>
            ))}
        </div>
    );
}
