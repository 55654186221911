import { createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import store from 'store2';

const store2Config = store.namespace('config');

const initialState = {
    isDarkMode: store2Config('isDarkMode') ?? false,
    listType: store2Config('listType') ?? 'list',
    sorting: store2Config('sorting') ?? { key: 'interest', order: 'desc' },
    detailed: store2Config('detailed') ?? true,
    activeCategories: store2Config('activeCategories') ?? ['interest', 'noInterest', 'maybe'],
    isAllCategoriesActive: store2Config('isAllCategoriesActive') ?? true,
    search: store2Config('search') ?? '',
};

const configsSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        toggleDarkMode(state) {
            state.isDarkMode = !state.isDarkMode;
            store2Config('isDarkMode', state.isDarkMode);
        },
        setListType(state, { payload }) {
            state.listType = payload || 'list';
            store2Config('listType', state.listType);
        },
        changeSortingType(state, { payload }) {
            const { key, order } = payload;
            state.sorting = { key, order };
            store2Config('sorting', state.sorting);
        },
        toggleCategory(state, { payload }) {
            if (payload === 'all') {
                state.activeCategories = ['interest', 'noInterest', 'maybe'];
                state.isAllCategoriesActive = true;
            } else if (state.isAllCategoriesActive) {
                state.activeCategories = [payload];
                state.isAllCategoriesActive = false;
            } else {
                const newActiveCategories = _.xor([...state.activeCategories], [payload]);
                state.activeCategories = newActiveCategories;
                state.isAllCategoriesActive = false;
            }

            store2Config('activeCategories', state.activeCategories);
            store2Config('isAllCategoriesActive', state.isAllCategoriesActive);
        },
        toggleDetailedList(state) {
            state.detailed = !state.detailed;
            store2Config('detailed', state.detailed);
        },
        setSearch(state, { payload }) {
            state.search = payload;
            store2Config('search', state.search);
        },
    },
});

export const {
    setListType,
    toggleDarkMode,
    toggleCategory,
    toggleDetailedList,
    changeSortingType,
    setSearch,
} = configsSlice.actions;
export default configsSlice.reducer;
