import { memo } from 'react';

export default memo(function Placeholder(props) {
    return (
        <div className="project-placeholder" {...props}>
            <div className="project-placeholder__title"></div>

            <div className="project-placeholder__footer">
                <div className="project-placeholder__footer--author">
                    <div className="project-placeholder__footer--author--image"></div>
                    <div className="project-placeholder__footer--author--name"></div>
                </div>
            </div>
        </div>
    );
});
