import { useEvent } from 'react-use';
import './TextLimiter.scss';

import { memo, useEffect, useRef, useState } from 'react';

export default memo(function TextLimiter({
    text,
    style,
    maxLines,
    collapsed,
    toggleDescription,
    focused,
    ...props
}) {
    const textElement = useRef(null);
    const [textClamped, setTextClamped] = useState(null);

    const handleTextChanged = () => {
        const textEl = textElement.current;
        setTextClamped(textEl.scrollHeight > textEl.offsetHeight);
    };

    useEffect(() => {
        handleTextChanged();
    }, [textElement, text]);

    useEvent('keydown', e => {
        // on space key, check if the project is focused and toggle the description
        if (e.keyCode !== 32) return;

        e.preventDefault();
        
        if (focused && textClamped) toggleDescription();
    });

    return (
        <div className="project-description-container">
            <p
                ref={textElement}
                style={{ ...style, WebkitLineClamp: collapsed ? 'unset' : maxLines }}
                data-clamped-text
                {...props}>
                {text}
            </p>

            {(textClamped || collapsed) && (
                <button className="toggle-full-description" onClick={toggleDescription}>
                    {collapsed ? 'Ver menos 🡑' : 'Ver mais 🡓'}
                </button>
            )}
        </div>
    );
});
