import './Loader.scss';

import { memo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

export default memo(function Loader() {
    const isLoading = useSelector(state => state.projects.isLoading);

    return (
        <div className={classNames('loader-overlay', isLoading && 'show')}>
            <Icon name="codicon:loading" className="animate-spin" />
        </div>
    );
});
