import { memo } from 'react';
import toast from 'react-hot-toast';

const apiURL = import.meta.env.VITE_API_URL;

export default memo(function Actions() {
    const handleUpdateProjects = async () => {
        const toastId = toast.loading('Atualizando projetos...');

        const response = await fetch(apiURL + 'restart', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        });

        const data = await response.json();

        if (data.error) {
            toast.error(data.message || 'Falha ao atualizar os projetos.', { id: toastId });
            return;
        }

        toast.success(data.message || 'Projetos atualizados com sucesso!', { id: toastId });
    };

    const handleRemoveUnavailable = async () => {
        const toastId = toast.loading('Removendo projetos indisponíveis...');

        const response = await fetch(apiURL + 'update', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        });

        const data = await response.json();

        if (data.error) {
            toast.error(data.message || 'Falha ao remover os projetos.', { id: toastId });
            return;
        }

        toast.success(data.message || 'Projetos removidos com sucesso!', { id: toastId });
    };

    return (
        <div className="sidebar-actions">
            <h3>Ações</h3>

            <div className="custom-buttons">
                <p className="custom-button" onClick={handleUpdateProjects}>
                    {/* clear icon */}
                    <Icon name="ic:twotone-cloud-sync" />
                    Atualizar projetos
                </p>

                <p className="custom-button" onClick={handleRemoveUnavailable}>
                    {/* clear icon */}
                    <Icon name="icon-park-twotone:clear" />
                    Remover indisponíveis
                </p>
            </div>
        </div>
    );
});
