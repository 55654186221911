import Skill from './Skill';
import defaultAvatar from '@images/user.png';
import { selectProjectAuthor, selectProjectSkills } from '@reducers/projects';

import { useSelector } from 'react-redux';

export default function Footer({ id }) {
    const detailed = useSelector(state => state.configs.detailed);

    const skills = useSelector(state => selectProjectSkills(state, id));
    const author = useSelector(state => selectProjectAuthor(state, id));

    return (
        detailed && (
            <div className="project-footer">
                <div className="skill-tags-container">
                    {skills?.map(({ text }) => (
                        <Skill text={text} key={text} />
                    ))}
                </div>

                <div className="author-container">
                    <img src={author.avatar ?? defaultAvatar} className="author-image" />

                    <div className="author-info">
                        <a href={author.link} className="author-name">
                            {author.name}
                        </a>
                        <span
                            className="author-stars"
                            style={{
                                '--rating': `${(author.rating * 100) / 5}%`,
                            }}>
                            {Array.from({ length: 5 }).map(_ => '★')}
                        </span>
                    </div>
                </div>
            </div>
        )
    );
}
