import { Icon as Iconify } from '@iconify/react';

import { memo } from 'react';

import classNames from 'classnames';

const Icon = memo(({ name, prefix, suffix, className, ...props }) => {
    const MemoizedIcon = memo(() => (
        <Iconify name={name} icon={name} className={classNames('svg-icon', className)} {...props} />
    ));
    return (
        <>
            {!!prefix && prefix}
            <MemoizedIcon />
            {!!suffix && suffix}
        </>
    );
});

export default Icon;

export { Icon };
