import CheckboxList from '@components/CheckboxList/CheckboxList';
import InputCheckbox from '@components/Input/InputCheckbox/InputCheckbox';
import { toggleCategory } from '@reducers/configs';
import { selectProjectsCount } from '@reducers/projects';

import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash';

const CategoryButton = memo(({ text, count, label, handler, active, icon }) => {
    return (
        <InputCheckbox
            icon={icon}
            type="button"
            key={label}
            label={
                <>
                    {text} <b>{count}</b>
                </>
            }
            handler={handler}
            isActive={active}
        />
    );
});

export default function Categories() {
    const dispatch = useDispatch();

    // const categorizedProjects = useSelector(state => selectCategorizedProjects(state), isEqual);
    const projectsCount = useSelector(state => selectProjectsCount(state).total);
    const activeCategories = useSelector(state => state.configs.activeCategories);
    const isAllCategoriesActive = useSelector(state => state.configs.isAllCategoriesActive);

    const showAllProjects = useCallback(() => dispatch(toggleCategory('all')), []);
    const toggleInterestProjects = useCallback(() => dispatch(toggleCategory('interest')), []);
    const toggleNoInterestProjects = useCallback(() => dispatch(toggleCategory('noInterest')), []);
    const toggleMaybeProjects = useCallback(() => dispatch(toggleCategory('maybe')), []);

    const categoriesCount = useSelector(state => selectProjectsCount(state), isEqual);

    const categories = useMemo(
        () => [
            {
                text: 'Todos',
                count: projectsCount,
                label: 'all',
                handler: showAllProjects,
                active: isAllCategoriesActive,
                icon: 'mdi:heart-multiple',
            },
            {
                text: 'Interesse',
                count: categoriesCount.interest,
                label: 'interest',
                handler: toggleInterestProjects,
                active: !isAllCategoriesActive && activeCategories.includes('interest'),
                icon: 'mdi:heart',
            },
            {
                text: 'Talvez',
                count: categoriesCount.maybe,
                label: 'maybe',
                handler: toggleMaybeProjects,
                active: !isAllCategoriesActive && activeCategories.includes('maybe'),
                icon: 'mdi:heart-half-full',
            },
            {
                text: 'Sem interesse',
                count: categoriesCount.noInterest,
                label: 'noInterest',
                handler: toggleNoInterestProjects,
                active: !isAllCategoriesActive && activeCategories.includes('noInterest'),
                icon: 'mdi:heart-outline',
            },
        ],
        [categoriesCount, activeCategories, isAllCategoriesActive]
    );

    return (
        <CheckboxList type="button" title="Classificação">
            <CategoryButton {...categories[0]} />
            <CategoryButton {...categories[1]} />
            <CategoryButton {...categories[2]} />
            <CategoryButton {...categories[3]} />
        </CheckboxList>
    );
}
