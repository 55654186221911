import TextLimiter from '@components/TextLimiter/TextLimiter';
import { selectProject, toggleDescription } from '@reducers/projects';

import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export default function Description({ id }) {
    const dispatch = useDispatch();

    const { description, descriptionCollapsed: collapsed, focused } = useSelector(
        state => selectProject(state, id, ['description', 'descriptionCollapsed', 'focused']),
        shallowEqual
    );
    const detailed = useSelector(state => state.configs.detailed);

    const handleToggleDescription = useCallback(() => {
        dispatch(toggleDescription(id));
    }, [id]);

    return (
        detailed && (
            <TextLimiter
                className="project-description"
                text={description?.replace(/\n{3,}/g, '\n\n')}
                maxLines={3}
                collapsed={collapsed}
                toggleDescription={handleToggleDescription}
                focused={focused}
            />
        )
    );
}
