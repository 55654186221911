import { selectProject } from '@reducers/projects';

import { memo, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useInterval } from 'react-use';

import dayjs from 'dayjs';

export default memo(function Date({ id }) {
    const { date } = useSelector(state => selectProject(state, id, ['date']), shallowEqual);

    const [formatedDate, setFormatedDate] = useState();

    const updateDate = () => {
        if (!date) return;

        const dayjsDate = dayjs(date);
        const formatStyle = dayjsDate.calendar(null, {
            sameDay: '[hoje às] HH:mm',
            lastDay: '[ontem às] HH:mm',
            lastWeek: 'dddd [às] HH:mm',
            sameElse: 'dddd [às] HH:mm',
        });

        const fromNow = dayjsDate.fromNow();

        setFormatedDate(`${formatStyle}・${fromNow}`);
    };

    useEffect(() => {
        updateDate();
    }, [date]);

    useInterval(() => {
        updateDate();
    }, 1000);

    return <span className="project-date">{formatedDate || '&nbsp;'}</span>;
});
