import './Sidebar.scss';

import Categories from './ListingOptions/Categories';
import Search from './ListingOptions/Search';
import SortBy from './ListingOptions/SortBy';
import Actions from './ListingOptions/Actions';
import ClassificationsCount from './ListingOptions/ClassificationsCount';
import LastUpdateTime from './ListingOptions/LastUpdateTime';

export default function Sidebar() {
    return (
        <aside>
            <Search />
            <Categories />
            <SortBy />
            <Actions />
            <ClassificationsCount />
            <LastUpdateTime />
        </aside>
    );
}
